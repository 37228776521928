import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (min-width: 1294px) {
    svg {
      width: 1294px;
    }
  }
`;

const Dots = () => (
  <Wrapper>
    <svg
      width="960"
      height="597"
      viewBox="0 0 1294 597"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M1247.56 11.5178C1244.05 12.996 1241.79 10.2984 1241.16 7.39075C1240.53 4.48312 1241.93 0.994174 1245.29 0.988382C1248.64 0.98259 1249.92 2.45515 1250.54 5.36278C1251.17 8.2704 1251.08 10.0397 1247.56 11.5178Z"
        fill="#6CA448"
      />
      <path
        opacity="0.6"
        d="M1289.56 200.518C1286.05 201.996 1283.79 199.298 1283.16 196.391C1282.53 193.483 1283.93 189.994 1287.29 189.988C1290.64 189.983 1291.92 191.455 1292.54 194.363C1293.17 197.27 1293.08 199.04 1289.56 200.518Z"
        fill="#6CA448"
      />
      <path
        opacity="0.6"
        d="M282.561 149.518C279.047 150.996 276.787 148.298 276.159 145.391C275.53 142.483 276.933 138.994 280.286 138.988C283.639 138.983 284.915 140.455 285.544 143.363C286.172 146.27 286.075 148.04 282.561 149.518Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M1150.99 147.835C1149.54 150.36 1146.92 149.771 1145.19 148.297C1143.46 146.824 1142.69 144.057 1144.73 142.499C1146.76 140.942 1148.22 141.244 1149.94 142.717C1151.67 144.191 1152.43 145.309 1150.99 147.835Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M1072.99 310.835C1071.54 313.36 1068.92 312.771 1067.19 311.297C1065.46 309.824 1064.69 307.057 1066.73 305.499C1068.76 303.942 1070.22 304.244 1071.94 305.717C1073.67 307.191 1074.43 308.309 1072.99 310.835Z"
        fill="#6CA448"
      />
      <path
        opacity="0.5"
        d="M76.9869 100.835C75.5395 103.36 72.9179 102.771 71.1891 101.297C69.4603 99.824 68.6944 97.0568 70.7265 95.4994C72.7585 93.942 74.2156 94.244 75.9444 95.7173C77.6732 97.1906 78.4343 98.3091 76.9869 100.835Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M491.987 535.835C490.54 538.36 487.918 537.771 486.189 536.297C484.46 534.824 483.694 532.057 485.726 530.499C487.759 528.942 489.216 529.244 490.944 530.717C492.673 532.191 493.434 533.309 491.987 535.835Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M205.987 7.83462C204.54 10.3601 201.918 9.77053 200.189 8.29725C198.46 6.82398 197.694 4.05685 199.726 2.49943C201.759 0.942009 203.216 1.24401 204.944 2.71728C206.673 4.19055 207.434 5.30911 205.987 7.83462Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M8.98693 282.835C7.53952 285.36 4.91789 284.771 3.18911 283.297C1.46033 281.824 0.694442 279.057 2.72648 277.499C4.75851 275.942 6.21561 276.244 7.94439 277.717C9.67317 279.191 10.4343 280.309 8.98693 282.835Z"
        fill="#6CA448"
      />
      <path
        opacity="0.4"
        d="M941.987 592.835C940.54 595.36 937.918 594.771 936.189 593.297C934.46 591.824 933.694 589.057 935.726 587.499C937.759 585.942 939.216 586.244 940.944 587.717C942.673 589.191 943.434 590.309 941.987 592.835Z"
        fill="#6CA448"
      />
    </svg>
  </Wrapper>
);

export default Dots;
